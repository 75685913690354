import React from 'react'
import './etiquetaPage.scss';
import logo  from '../../images/logo_service_texto.png';
import QRCode from 'qrcode.react';
import { generarPdf } from '../../services/etiqueta';

const EtiquetaPage = props => {

	const downloadQR = () => {
		const canvas = document.getElementById("QRCode");
		const pngUrl = canvas
		  .toDataURL("image/png")
		generarPdf(pngUrl, props.ticket, props.cliente)
	  };

    return (
	<html style={{border: "1px solid", borderRadius: "10px"}}>
		<body>
			<div width="250" >
				<table width="250" bgcolor="ffffff" style={{margin:"0 auto"}}>
					<tr>
						<td align="center">
							<table border="0" align="center" width="250" className="container590 bg_color" style={{fontSize: '20px', borderSpacing: "2em", color: '#A8AAAB', fontFamily: 'Century Gothic, Helvetica, sans-serif'}}>
								<tr>
									<td >
										<p>Ticket {props.ticket}</p>
									</td>
								</tr>
								<tr>
									<td >
										{<QRCode id="QRCode" bgColor="#FFFFFF" fgColor="#000000" size={180} level="H" value={props.idSalesforce} />}<br />
									</td>
								</tr>
								<tr>
									<td >
										<button className="blue_button" onClick={downloadQR}>Imprimir Etiqueta</button>
									</td>
								</tr>
							</table>
						</td>
					</tr>
				</table>
			</div>
		</body>
	</html>
    )
}

export default EtiquetaPage