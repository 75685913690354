import React from "react";
import "./presupuestoPage.scss";
import logo from "../../images/logo_service_texto.png";

const PresupuestoPage = (props) => {
  function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  function capitalizeExceptFirst(str) {
    const text = str.split("-")[1] ?? str;
    const words = text.split(/[ -]/);
    const capitalizedWords = words.map((word) => capitalizeFirstLetter(word));
    const result = capitalizedWords.join(" ");

    return result;
  }

  const lista = props.presupuesto.lineas.map((fila, index) => {
    return (
      <tr>
        <td className="bord-2">
          {fila.idProducto <= 11 ? fila.idProducto : "P" + index.toString()}
        </td>
        <td className="bord-2">{capitalizeExceptFirst(fila.descripcion)}</td>
        <td className="bord-2 right">{(fila.precio / 1.21).toFixed(2)} €</td>
      </tr>
    );
  });

  return (
    <html>
      <body>
        <div id="todo">
          <table border="0" width="100%" bgcolor="ffffff">
            <tr>
              <td>
                <table
                  align="center"
                  border="0"
                  width="560"
                  bgcolor="ffffff"
                  className="container600 "
                >
                  <tr>
                    <td>
                      <table
                        border="0"
                        align="center"
                        width="600"
                        className="container590"
                        bgcolor="ffffff"
                      >
                        <tr>
                          <td
                            align="center"
                            className="logo"
                            style={{
                              width: "100%",
                              fontSize: "0px",
                              padding: "10px",
                            }}
                          >
                            <a
                              href="https://www.intexserviceiberia.com/"
                              style={{
                                fontSize: "0px",
                                display: "block",
                                borderStyle: "none !important",
                                border: "0 !important",
                              }}
                            >
                              <img
                                width="100"
                                border="0"
                                style={{ display: "block", width: "150px" }}
                                src={logo}
                                alt="Logo Distria"
                              />
                            </a>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table border="0" width="560" className="bg_color" bgcolor="ffffff">
            <tr>
              <td>
                <table
                  align="center"
                  border="0"
                  width="560"
                  className="container600"
                  style={{
                    fontSize: "14px",
                    color: "#444444",
                    fontFamily: "Century Gothic, Helvetica, sans-serif",
                    lineHeight: "24px",
                    textAlign: "left",
                  }}
                >
                  <tr>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "900",
                        lineHeight: "28px",
                      }}
                    >
                      {" "}
                      Detalles del presupuesto
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Ticket:</strong>{" "}
                      {props.presupuesto.presupuesto.idPresupuesto}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Nombre:</strong> {props.cliente.Name}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Fecha:</strong>{" "}
                      {props.presupuesto.presupuesto.fechaCreacion}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table border="0" width="560" className="bg_color" bgcolor="ffffff">
            <tr>
              <td>
                <table
                  align="center"
                  border="0"
                  width="560"
                  className="container600"
                  style={{
                    fontSize: "14px",
                    color: "#444444",
                    fontFamily: "Century Gothic, Helvetica, sans-serif",
                    fontWeight: "900",
                    lineHeight: "24px",
                  }}
                >
                  <tr>
                    <td
                      height="10"
                      style={{ fontSize: "5px", lineHeight: "5px" }}
                    >
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table
            id="productos"
            border="0"
            width="560"
            className="bg_color"
            bgcolor="ffffff"
          >
            <tr>
              <td>
                <table
                  align="center"
                  width="560"
                  className="container600"
                  style={{
                    textAlign: "left",
                    border: "2px #eeeeee solid",
                    color: "#444444",
                    fontFamily: "Century Gothic, Helvetica, sans-serif",
                  }}
                >
                  <thead style={{ fontSize: "12px" }}>
                    <tr>
                      <td width="10%" className="bord-1">
                        Ref.
                      </td>
                      <td width="75%" className="bord-1">
                        Reparación
                      </td>
                      <td width="15%" className="bord-1">
                        Precio
                      </td>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: "12px;" }}>{lista}</tbody>
                  <tfoot style={{ fontSize: "14px", textAlign: "right" }}>
                    <tr>
                      <td colSpan="2" className="referencia bord-2">
                        Total Neto
                      </td>
                      <td className="bord-2 referencia">
                        <strong>
                          {(props.presupuesto.presupuesto.total / 1.21).toFixed(
                            2
                          )}{" "}
                          &euro;
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="referencia bord-2">
                        Total IVA 21%
                      </td>
                      <td className="bord-2 referencia">
                        <strong>
                          {(
                            props.presupuesto.presupuesto.total -
                            props.presupuesto.presupuesto.total / 1.21
                          ).toFixed(2)}{" "}
                          &euro;
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="total bord-2">
                        Total
                      </td>
                      <td className="bord-2 total">
                        <strong>
                          {props.presupuesto.presupuesto.total} &euro;
                        </strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </td>
            </tr>
          </table>
          <table border="0" width="560" className="bg_color" bgcolor="ffffff">
            <tr>
              <td>
                <table
                  align="center"
                  border="0"
                  width="560"
                  className="container600"
                  style={{
                    textAlign: "center",
                    border: "2px #eeeeee solid",
                    color: "#444444",
                    fontFamily: "Century Gothic, Helvetica, sans-serif;",
                  }}
                >
                  <tr>
                    <td>
                      <table
                        border="0"
                        width="270"
                        align="left"
                        style={{ borderCollapse: "collapse" }}
                        className="section-item-btn"
                      >
                        <tr>
                          <td
                            align="left"
                            className="section-item-btn"
                            style={{
                              fontSize: "11px",
                              padding: "5px",
                              borderRight: "1px #eeeeee solid",
                            }}
                          >
                            <p style={{ fontSize: "12px" }}>
                              <strong>Dirección de Envío:</strong>
                            </p>
                            <p>{props.cliente.Name}</p>
                            <p>{props.cliente.ShippingStreet}</p>
                            <p>{props.cliente.ShippingCity}</p>
                            <p>{props.cliente.ShippingPostalCode}</p>
                            <p>{props.cliente.ShippingState}</p>
                            <p>{props.cliente.ShippingCountry}</p>
                            <p>{props.cliente.PersonEmail}</p>
                          </td>
                        </tr>
                      </table>
                      <table
                        border="0"
                        width="270"
                        align="right"
                        style={{ borderCollapse: "collapse" }}
                        className="section-item-btn"
                      >
                        <tr>
                          <td
                            align="left"
                            className="section-item-btn"
                            style={{ fontSize: "11px", padding: "5px" }}
                          >
                            <p style={{ fontSize: "14px" }}>
                              <strong>Dirección de Facturación:</strong>
                            </p>
                            <p>{props.cliente.Name}</p>
                            <p>{props.cliente.BillingStreet}</p>
                            <p>{props.cliente.BillingCity}</p>
                            <p>{props.cliente.BillingPostalCode}</p>
                            <p>{props.cliente.BillingState}</p>
                            <p>{props.cliente.ShippingCountry}</p>
                            <p>{props.cliente.PersonEmail}</p>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table border="0" width="560" bgcolor="ffffff">
            <tr>
              <td align="center">
                <table
                  border="0"
                  align="center"
                  width="560"
                  className="container590 bg_color"
                  style={{
                    border: "2px #eeeeee solid",
                    fontSize: "12px",
                    color: "#444444",
                    fontFamily: "Century Gothic, Helvetica, sans-serif",
                  }}
                >
                  <tr>
                    <td
                      style={{
                        fontSize: "11px",
                        padding: "5px",
                        borderRight: "1px #eeeeee solid",
                      }}
                    >
                      <p>
                        <strong>Observaciones:</strong>
                      </p>
                      <p>{props.presupuesto.presupuesto.observaciones}</p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>

          <table width="560" bgcolor="ffffff">
            <tr>
              <td align="center">
                <table
                  border="0"
                  align="center"
                  width="560"
                  className="container590 bg_color"
                  style={{
                    fontSize: "10px",
                    color: "#A8AAAB",
                    fontFamily: "Century Gothic, Helvetica, sans-serif",
                  }}
                >
                  <tr>
                    <td>
                      <p>
                        * Se considera aceptado el presente presupuesto una vez
                        se haya recibido el pago del mismo, siguiendo las
                        instrucciones enviadas por e-mail junto a este
                        documento.
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </body>
    </html>
  );
};

export default PresupuestoPage;
