import axios from 'axios';

import '../config';

export const pagarPresupuesto = async (datos) => {
    
    const config = {
      params: datos,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
    };
    return await axios.get(global.ruta+"/presupuestos/pagarPresupuesto",config)
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log(err)
    })
      
  }