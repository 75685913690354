import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { NotificationManager } from "react-notifications";
import { useEffect, useState } from "react";
import "../../config";
import { createDoneWorks, getWorkTypes } from "../../services/reparacion";

const columns = [
  0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8,
];

const Works = (props) => {
  const [workTypes, setWorkTypes] = useState([]);
  const [selectedWorkTypes, setSelectedWorkTypes] = useState(
    props.reparacion?.trabajos || []
  );
  const [hour, setHour] = useState();
  const [selectedWorkType, setSelectedWorkType] = useState();
  const [observations, setObservations] = useState(
    props.reparacion?.datos.observacionesTrabajosRealizados
  );
  const [intern, setIntern] = useState(props.intern || "");

  useEffect(() => {
    getWorkTypes().then((wts) => setWorkTypes(wts));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSelectedWorkTypes([{ ...selectedWorkType, horas: hour }]);
    setHour();
    setObservations(selectedWorkType.descripcion);
    setSelectedWorkType(null);
  };

  const deleteSelectedType = (id) => {
    setSelectedWorkTypes(selectedWorkTypes.filter((swt) => swt.id !== id));
    setObservations("");
  };

  const handleSend = () => {
    selectedWorkTypes.length > 0 &&
      createDoneWorks(
        props.ticket,
        {
          selectedWorkTypes,
          observations,
          intern,
        },
        props.idSalesforce
      )
        .then((res) => {
          NotificationManager.success("Guardado con éxito");
          props.actualizar({
            ...props.reparacion,
            trabajos: selectedWorkTypes,
            datos: {
              ...props.reparacion.datos,
              observacionesTrabajosRealizados: observations,
            },
          });
        })
        .catch((err) => NotificationManager.error("Se ha producido un error"));
  };

  return (
    <form onSubmit={handleSubmit} method="post">
      <Grid container columnSpacing={2}>
        <Grid item xs={5}>
          <Autocomplete
            id="workType"
            className="autocomplete"
            value={selectedWorkType || null}
            options={workTypes.filter(
              (workType) =>
                workType.idTipoProducto === props.reparacion.datos.tipoProducto
            )}
            getOptionLabel={(option) => option.nombre}
            onChange={(e, v) => {
              setSelectedWorkType(v);
              setHour(v.horas);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipo de trabajo"
                name="workType"
                required={true}
              />
            )}
          />
        </Grid>
        <Grid item xs={5}>
          <Autocomplete
            id="hours"
            className="autocomplete"
            multiple={false}
            required={true}
            value={hour || null}
            options={columns}
            getOptionLabel={(option) => option}
            onChange={(e, v) => setHour(v)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Horas"
                name="workType"
                required={true}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={2}
          // style={{ justifyContent: "flex-end", display: "flex" }}
        >
          <button type="submit" className="blue_button">
            + Añadir Línea
          </button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 5 }}>
          <table width="100%">
            <tr style={{ color: "#2a8daa" }}>
              <td></td>
              <td>Nombre</td>
              <td>Horas</td>
            </tr>
            {selectedWorkTypes.length === 0 ? (
              <tr>
                <td colSpan="4" style={{ color: "#C4C4C4" }}>
                  No hay ningún trabajo registrado
                </td>
              </tr>
            ) : (
              selectedWorkTypes.map((sw) => (
                <tr>
                  <td style={{ idth: "15px" }}>
                    <button
                      type="button"
                      onClick={() => deleteSelectedType(sw.id)}
                    >
                      <ClearIcon />
                    </button>
                  </td>
                  <td>{sw.nombre.toUpperCase()}</td>
                  <td>{sw.horas}</td>
                </tr>
              ))
            )}
          </table>
          <hr color="#2a8daa" />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "40px" }}>
          <TextField
            fullWidth
            name="observaciones"
            placeholder="Observaciones"
            id="observaciones"
            label="Observaciones"
            variant="outlined"
            multiline={true}
            value={observations}
            rows={3}
            onChange={(e) => setObservations(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "40px" }}>
          <TextField
            fullWidth
            name="interno"
            placeholder="Comentario Interno"
            id="observaciones"
            label="Comentario Interno"
            variant="outlined"
            multiline={true}
            value={intern}
            rows={3}
            onChange={(e) => setIntern(e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <button
            type="button"
            className="blue_button"
            onClick={handleSend}
            style={{ marginTop: "20px" }}
          >
            GUARDAR TRABAJOS
          </button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Works;
