import React from "react";
import "./App.css";
import Header from "./components/header/header";
import Taller from "./pages/taller/taller";
import Pagos from "./pages/pagos/pagos";
import ShowQR from "./components/qrcode/showQR";
import Confirmacion from "./pages/confirmacion/confirmacion";
import PresupuestoPage from "./pages/presupuesto/presupuestoPage";
import { Routes, Route, useLocation } from "react-router-dom";
import { NotificationContainer } from "react-notifications";

const App = () => {
  const location = useLocation();

  return (
    <div className="App">
      {location.pathname.includes("/obtenerqr/") ? <div></div> : <Header />}
      <Routes>
        <Route path="/" element={<Taller />} />
        <Route path="pagoPresupuesto/:id" element={<Pagos />} />
        <Route path="presupuesto/:id/:res" element={<Confirmacion />} />
        <Route path="paginaPresupuesto/:id" element={<PresupuestoPage />} />
        <Route path="obtenerqr/:id" element={<ShowQR />} />
      </Routes>
      <NotificationContainer />
    </div>
  );
};

export default App;
