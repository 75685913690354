import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { NotificationManager } from "react-notifications";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  getProductTypes,
  getReceivedProducts,
  getReparacion,
  getTransportTypes,
  createRepair,
  setProductos,
} from "../../services/reparacion";
import "../../config";
import { getProductos } from "../../services/producto";

const useStyles = makeStyles((theme) => ({
  line: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    backgroundColor: 'white',
    border: "2px solid #2a8daa",
    padding: '2px',
    borderRadius: "10px",
    "&:focus": {
      outline: "none",
    },
    width: "80%",
    overflowY: "scroll",
  },
}));

const Pesaje = (props) => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const [product_types, setProductTypes] = useState([]);
  const [transport_types, setTransportTypes] = useState([]);
  const [received_products, setReceivedProducts] = useState([]);
  const [received_pieces, setReceivedPieces] = useState([]);
  const [product_type, setProductType] = useState();
  const [transport, setTransport] = useState("");
  const [product_options, setProductOptions] = useState([]);
  const [received_product, setReceivedProduct] = useState(null);

  const guardarPesaje = async (data) => {
    const promiseCreateRepair = createRepair(props.ticket, product_type?.id, {
      ...data,
      transporte: transport?.id || null,
      idReceivedProduct: received_product?.idProducto || null,
    });
    const promiseSetProduct = setProductos(
      props.ticket.CaseNumber,
      received_pieces
    );
    await Promise.all([promiseCreateRepair, promiseSetProduct]).then(
      ([respRep, resSetPd]) => {
        if (resSetPd && respRep) {
          NotificationManager.success("¡Guardado con éxito!");
        } else {
          NotificationManager.error(
            "Revisa los campos",
            "Se ha producido un error..."
          );
        }
      }
    );
    const repar = await getReparacion(props.ticket.CaseNumber);
    props.actualizar(repar, product_type);
  };

  useEffect(() => {
    setProductType();
    getProductTypes().then((types) => {
      if (types.length > 0) {
        setProductTypes(types);
        setProductType(types.find((t) => t.id === props.tipoProducto));
      }
    });
    getTransportTypes().then((types) => {
      if (types.length > 0) {
        setTransportTypes(types);
        setTransport(
          types.find(
            (t) =>
              t.id.toString() === props.reparacion?.datos.transporte.toString()
          )
        );
      }
    });
    getProductos().then((prods) => {
      if (prods.productos.length > 0) {
        setProductOptions(prods.productos);
        setReceivedProduct(
          prods.productos.find(
            (p) => p.idProducto === props.reparacion?.datos.idReceivedProduct
          ) || null
        );
      }
    });
    getReceivedProducts().then((prods) => {
      if (prods.length > 0) {
        setReceivedProducts(prods);
        var pieces = prods.reduce((a, v) => ({ ...a, [v.name]: false }), {});
        setReceivedPieces(props.reparacion?.piezas || pieces);
      }
    });
  }, []);

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(guardarPesaje)}
    >
      <div key={props.id} className={classes.line}>
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              fullWidth
              id="product_type"
              options={product_types}
              getOptionLabel={(option) => option.name}
              value={product_type || null}
              onChange={(e, v) => {
                setProductType(v);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Tipo de producto" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              fullWidth
              id="product_type"
              options={transport_types}
              getOptionLabel={(option) => option.name}
              value={transport || null}
              onChange={(e, v) => setTransport(v)}
              renderInput={(params) => (
                <TextField {...params} label="Empresa de transporte" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="nexpediente"
              placeholder="Número de expediente"
              id="nexpediente"
              label="Nº de expediente"
              variant="outlined"
              sx={{ width: "100%" }}
              {...register("nexpediente", {
                required: {
                  value: true,
                  message: "Ingrese el número de expediente",
                },
              })}
              defaultValue={
                props.reparacion && props.reparacion.datos
                  ? props.reparacion.datos.nexpediente
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              name="peso"
              placeholder="Peso"
              id="peso"
              label="Peso"
              variant="outlined"
              sx={{ width: "100%" }}
              {...register("peso", {
                required: { value: true, message: "Ingrese un nombre" },
              })}
              defaultValue={
                props.reparacion && props.reparacion.datos
                  ? props.reparacion.datos.peso
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              name="medidas"
              placeholder="Medidas"
              id="medidas"
              label="Medidas"
              variant="outlined"
              sx={{ width: "100%" }}
              {...register("medidas", {
                required: { value: true, message: "Ingrese un nombre" },
              })}
              defaultValue={
                props.reparacion && props.reparacion.datos
                  ? props.reparacion.datos.medida
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="bultos"
              placeholder="Bultos"
              id="bultos"
              label="Bultos"
              variant="outlined"
              sx={{ width: "100%" }}
              {...register("bultos", {
                required: { value: true, message: "Ingrese los bultos" },
              })}
              defaultValue={
                props.reparacion && props.reparacion.datos
                  ? props.reparacion.datos.bultos
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              fullWidth
              id="received_product"
              options={product_options}
              getOptionLabel={(option) =>
                option.referencia + " - " + option.nombre
              }
              value={received_product || null}
              onChange={(e, v) => setReceivedProduct(v)}
              renderInput={(params) => (
                <TextField {...params} label="Modelo recibido" />
              )}
            />
          </Grid>
        </Grid>
      </div>
      <h3 style={{ padding: "10px", color: "#117864", textAlign: "center" }}>
        Productos recibidos
      </h3>
      {product_type ? (
        <>
          {received_products
            ?.filter((prod) => prod.idTipoProducto === product_type.id)
            ?.map((prod) => (
              <FormControlLabel
                control={
                  <Checkbox
                    name={prod.name}
                    checked={
                      received_pieces[prod.name] === 1 ||
                      received_pieces[prod.name] === true
                        ? true
                        : false
                    }
                    onChange={(e, value) => {
                      setReceivedPieces({
                        ...received_pieces,
                        [prod.name]: value,
                      });
                    }}
                    sx={{
                      "&.Mui-checked": {
                        color: "rgb(17, 120, 100)",
                      },
                    }}
                  />
                }
                label={prod.label.toUpperCase()}
                style={{ margin: "0 10px" }}
              />
            ))}
        </>
      ) : (
        <div>
          <p style={{ textAlign: "center" }}>
            Selecciona un tipo de producto para mostrar los productos
          </p>
        </div>
      )}
      <div className={classes.line}>
        <TextField
          defaultValue={props.reparacion?.datos?.observaciones ?? ""}
          name="observaciones"
          placeholder="Observaciones"
          id="observaciones"
          label="Observaciones"
          variant="outlined"
          multiline={true}
          rows={3}
          sx={{ m: 2, minWidth: 200, width: "80%" }}
          {...register("observaciones", {
            required: { value: false, message: "Ingrese un nombre" },
          })}
        />
      </div>
      <div className={classes.line}>
        <button type="submit" className="blue_button">
          GUARDAR REGISTRO
        </button>
      </div>
    </form>
  );
};

export default Pesaje;
