import { Modal, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { QRCodeCanvas } from "qrcode.react";
import { useState } from "react";
import logoSearch from "../../images/barcode.png";
import { generarPdf } from "../../services/etiqueta";
import { obtenerTicket, obtenerTicketInterno } from "../../services/tickets";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30%",
    margin: "0 auto",
  },
  paper: {
    backgroundColor: 'white',
    border: "2px solid #2a8daa",
    padding: '2px',
    borderRadius: "10px",
    "&:focus": {
      outline: "none",
    },
  },
  line: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  table: {
    display: "flex",
    justifyContent: "center",
    minHeight: "100px",
    padding: "10px",
  },
}));

const ModalPistola = (props) => {
  const [elegida, setElegida] = useState(null);

  const keyPress = async (e) => {
    if (e.keyCode === 13) {
      setElegida(e.target.value.slice(3));
      if (localStorage.getItem("idSeccion") === "1") {
        var dev = await obtenerTicket(e.target.value.slice(3));
        await props.setTicket(
          {idTicket: e.target.value.slice(3)},
          dev.CB_Estado_del_ticket__c === "Pdte Recibir Pieza"
        );
        const canvas = document.getElementById("QRCodeMod");
        const pngUrl = canvas.toDataURL("image/png");
        generarPdf(pngUrl, dev.CaseNumber, dev.AccountId);
        props.close();
      } else {
        const dev = await obtenerTicket(e.target.value.slice(3));
        const interno = await obtenerTicketInterno(dev.CB_Ticket_Interno__c);
        await props.setTicket(
          {
            idInterno: dev.CB_Ticket_Interno__c,
            idTicket: e.target.value.slice(3),
          },
          interno.Estado_del_ticket__c === "Pdte Recibir Pieza"
        );
        props.close();
      }
    }
  };

  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="title"
      aria-describedby="description"
      open={props.state}
      onClose={props.close}
      className={classes.modal}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "30%",
          margin: "0 auto",
          minWidth: "500px",
          backgroundColor: "white",
          border: "5px solid #688A91",
          borderRadius: "10px",
        }}
      >
        <h1 style={{ margin: "30px 0" }}> Escaneando QR... </h1>
        <img src={logoSearch} alt="readingqr" style={{ maxWidth: "200px" }} />

        <TextField
          style={{ margin: "30px 0", width: "300px" }}
          id="code"
          autoFocus
          onKeyDown={keyPress}
        />

        {elegida ? (
          <QRCodeCanvas
            id="QRCodeMod"
            bgColor="#FFFFFF"
            fgColor="#000000"
            size={180}
            level="H"
            value={elegida}
            style={{ display: "none" }}
          />
        ) : null}
      </Box>
    </Modal>
  );
};

export default ModalPistola;
