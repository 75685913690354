import axios from 'axios';

import '../config';

export const obtenerProducto = async (id) => {
  
  return await axios.get(global.ruta+"/presupuestos/producto/"+id, {params: {access_token: global.token}})
  .then(response => {
    return response.data
  })
  .catch(err => {
    console.log(err)
  })
    
}


export const getProductos = async () => {
  
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
  };

  return await axios.get(global.ruta+"/reparaciones/productos", config)
  .then(response => {
    return response.data
  })
  .catch(err => {
    console.log(err)
  })
    
}