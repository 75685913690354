import axios from 'axios';
import { jsPDF } from 'jspdf';
import ReactDOMServer from 'react-dom/server';
import '../config';
import PresupuestoPage from '../pages/presupuesto/presupuestoPage';
import PresupuestoPagePT from '../pages/presupuesto/presupuestoPage_pt';
import { obtenerCliente } from './tickets';

export const addPresupuesto = async (presupuesto) => {
  
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
  };
  return await axios.post(global.ruta+"/presupuestos", {presupuesto}, config)
  .then(response => {
    return response.status
  })
  .catch(err => {
    console.log(err)
  })
    
}

export const addLineasPresupuesto = async (id, lineas) => {
  
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
  };
  return await axios.post(global.ruta+"/presupuestos/addLineas/"+id, {lineas}, config)
  .then(response => {
    return response.status
  })
  .catch(err => {
    console.log(err)
  })
    
}


export const getPresupuesto = async (id) => {

  const config = {
    params: {hash: id.toString()},
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
  };
  return await axios.get(global.ruta+"/presupuestos", config)
  .then(response => {
    return response.data
  })
  .catch(err => {
    console.log(err)
  })
    
}

export const generarPdf = async (ticket, cliente, pais) => {

  var pres = await getPresupuesto(ticket)
  var cli = await obtenerCliente(cliente)

  const group = pais==='España' ? <PresupuestoPage presupuesto={pres} cliente={cli} /> : <PresupuestoPagePT presupuesto={pres} cliente={cli} /> ;

  const input =  ReactDOMServer.renderToStaticMarkup(group);

  var doc = new jsPDF('p', 'pt', 'letter');
  doc.html(input, {
      callback: function(pdf) {
        pdf.save('presupuesto.pdf');
        window.open(pdf.output('bloburl'));
      }
  });  

}