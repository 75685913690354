import { Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Button, Grid } from "@mui/material";
import { useState } from "react";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import { NotificationManager } from "react-notifications";
import "react-html5-camera-photo/build/css/index.css";
import "../../config";
import { addImagenProd } from "../../services/stock";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "80%",
    margin: "50px auto",
    overflowY: "auto",
  },
  paper: {
    backgroundColor: 'white',
    border: "2px solid #2a8daa",
    padding: '2px',
    borderRadius: "10px",
    "&:focus": {
      outline: "none",
    },
  },
  line: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  table: {
    display: "flex",
    justifyContent: "center",
    minHeight: "100px",
    padding: "10px",
  },
}));

const ModalCameraStock = (props) => {
  const classes = useStyles();
  const [picture, setPicture] = useState("");

  const handleAdjuntar = () => {
    addImagenProd(picture, props.idProduct).then((response) => {
      NotificationManager.success("Imagen adjuntada con éxito.");
      props.updateStock(response.data.url);
    });
  };

  return (
    <Modal
      aria-labelledby="title"
      aria-describedby="description"
      open={props.open}
      className={classes.modal}
      onClose={props.close}
    >
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{ maxWidth: "100%", height: "auto" }}>
              <Camera
                onTakePhoto={(pic) => {
                  setPicture(pic);
                }}
                errorMessages={{
                  noCameraAccessible:
                    "No se puede acceder a la cámara o no se encuentra.",
                  permissionDenied:
                    "Permiso denegado. Recarga y acepta los permisos.",
                }}
                idealFacingMode={FACING_MODES.ENVIRONMENT}
                idealResolution={{ width: 640, height: 480 }}
                sizeFactor={1}
              />
            </div>
          </Grid>
          {picture ? (
            <Grid item xs={12}>
              <div style={{ maxWidth: "100%", height: "auto" }}>
                <img
                  src={picture}
                  style={{ maxWidth: "100%", height: "auto" }}
                  alt="foto"
                />
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Button
                    style={{
                      backgroundColor: "#00D903",
                      color: "white",
                      width: "100%",
                    }}
                    startIcon={<FileUploadIcon sx={{ mr: 1 }} />}
                    onClick={() => handleAdjuntar()}
                  >
                    Adjuntar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    style={{
                      backgroundColor: "#E90000",
                      color: "white",
                      width: "100%",
                    }}
                    startIcon={<DeleteIcon sx={{ mr: 1 }} />}
                    onClick={() => setPicture("")}
                  >
                    Borrar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </div>
    </Modal>
  );
};

export default ModalCameraStock;
