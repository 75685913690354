import React from "react";
import { jsPDF } from "jspdf";
import ReactDOMServer from "react-dom/server";
import "../config";
import { obtenerCliente } from "./tickets";

export const generarPdf = async (urlImg, ticket, cliente) => {
  var cli = await obtenerCliente(cliente);

  const group = (
    <html>
      <body>
        <div id="todo">
          <table bgcolor="ffffff">
            <tr>
              <td align="left">
                <table
                  border="0"
                  align="left"
                  className="container590 bg_color"
                  style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  <tr>
                    <td style={{ paddingRight: "40px" }}>
                      <p style={{ padding: "10px", margin: " 0 0 0 -10px" }}>
                        Ticket {ticket}
                      </p>
                      <p
                        style={{
                          padding: "10px",
                          margin: " 0 0 25px -10px",
                          inlineSize: "150px",
                          overflow: "hidden"
                        }}
                      >
                        {cli.Name.length > 20
                          ? cli.Name.substring(0, 20)
                          : cli.Name}
                      </p>
                    </td>
                    <td>
                      <img src={urlImg} alt="qr" width="170px" />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </body>
    </html>
  );

  const input = ReactDOMServer.renderToStaticMarkup(group);

  var doc = new jsPDF("l", "pt", "a6");
  doc.html(input, {
    callback: function (pdf) {
      window.open(pdf.output("bloburl")).print();
    },
  });
};
