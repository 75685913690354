import { makeStyles } from "@mui/styles";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import "../../config";
import {
  getDefectTypes,
  getReparacion,
  setErrores,
} from "../../services/reparacion";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vh",
    margin: "0 auto",
  },
  paper: {
    backgroundColor: 'white',
    border: "2px solid #2a8daa",
    padding: '2px',
    borderRadius: "10px",
    "&:focus": {
      outline: "none",
    },
    width: "100vh",
  },
  line: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    color: "grey",
  },
  table: {
    display: "flex",
    justifyContent: "center",
    minHeight: "100px",
    padding: "10px",
  },
}));

const Errores = (props) => {
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm();
  const [defect_types, setDefectTypes] = useState();

  const guardarErrores = async (data) => {
    const err = await setErrores(props.ticket, data);
    if (err) NotificationManager.success("Guardado con éxito");
    else NotificationManager.error("Se ha producido un error...");
    const repar = await getReparacion(props.ticket);
    props.actualizar(repar);
  };

  useEffect(() => {
    getDefectTypes().then((defects) => {
      if (defects.length > 0) {
        setDefectTypes(defects);
      }
    });
  }, []);

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(guardarErrores)}
    >
      <h3 style={{ padding: "10px", color: "#117864", textAlign: "center" }}>
        Defectos del producto
      </h3>

      {props.tipoProducto ? (
        <>
          {defect_types
            ?.filter((type) => type.idTipo === props.tipoProducto)
            ?.map((type) => (
              <FormControlLabel
                key={type.id}
                control={
                  <Checkbox
                    name={type.name}
                    defaultChecked={
                      props.reparacion && props.reparacion.errores
                        ? props.reparacion.errores[type.name] === true ||
                          props.reparacion.errores[type.name] === 1
                          ? true
                          : false
                        : false
                    }
                    {...register(type.name, {
                      required: { value: false, message: "Ingrese un nombre" },
                    })}
                    sx={{
                      margin: 0,
                      "&.Mui-checked": {
                        color: "rgb(17, 120, 100)",
                      },
                    }}
                  />
                }
                label={type.label.toUpperCase()}
                style={{ margin: "0 10px" }}
              />
            ))}
        </>
      ) : (
        <div>
          <p style={{ textAlign: "center" }}>
            Selecciona un tipo de producto para mostrar los productos
          </p>
        </div>
      )}
      {props.tipoProducto ? (
        <TextField
          label="Comentarios"
          multiline
          style={{ marginTop: "20px", width: "100%" }}
          rows={5}
          defaultValue={props?.reparacion?.errores?.comentarios ?? null}
          {...register("comentarios", {
            required: { value: false, message: "Ingrese un comentario" },
          })}
        />
      ) : null}
      <div className={classes.line}>
        <button
          type="submit"
          className="blue_button"
          style={{ margin: "10px" }}
        >
          GUARDAR
        </button>
      </div>
    </form>
  );
};

export default Errores;
