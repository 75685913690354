import axios from 'axios';

import '../config';

export const loginUser = async (credentials) => {
  
  const config = {
    header : {
    'Access-Control-Allow-Origin': '*'
    }
  }

    return await axios.get(global.ruta+"/users/loginUser", {params: credentials}, config)
    .then(response => {
      global.accesToken = response.data.token;
      localStorage.setItem('token',  response.data.token);
      localStorage.setItem('idSeccion',  response.data.usuario.idSeccion);
      return response.data.token
    })
    .catch(err => {
      console.log(err)
    })
      
  }