import { List } from "@mui/material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import React, { useState } from "react";
import "./lista.scss";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import HighlightIcon from "@mui/icons-material/Highlight";
import ListItems from "./listItem/listItem";
import { isMobile } from "react-device-detect";

const Lista = (props) => {
  const [search, setSearch] = useState();

  return (
    <List
      style={{
        height: localStorage.getItem("idSeccion") === "1" ? "78vh" : "100%",
        overflow:
          localStorage.getItem("idSeccion") === "1"
            ? "overlay"
            : isMobile
            ? null
            : "overlay",
        padding: "0 10px 0 0",
      }}
      className={"listaTickets"}
    >
      {isMobile ? (
        <Button
          type="submit"
          className="scan_button"
          onClick={props.openModal}
          endIcon={<CameraAltIcon />}
        >
          LEER ETIQUETA
        </Button>
      ) : (
        <Button
          className="scan_button"
          onClick={props.openModalPistola}
          endIcon={<HighlightIcon />}
        >
          LEER ETIQUETA
        </Button>
      )}

      <TextField
        placeholder="Nombre o nº de ticket..."
        id="standard-bare"
        size="small"
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton>
                <FilterAltIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          margin: "10px 0",
          backgroundColor: "white",
        }}
      />
      {props.data &&
        props.data
          .filter((ticket) =>
            search
              ? ticket.SuppliedName?.toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    search
                      ?.toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  ) ||
                ticket.CaseNumber?.toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    search
                      ?.toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  ) ||
                ticket.PedCli__c?.toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    search
                      ?.toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  ) ||
                ticket.CB_Descripcion__c?.toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    search
                      ?.toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  ) ||
                ticket.CB_Tracking__c?.toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    search
                      ?.toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  ) ||
                ticket.Nombre_del_cliente__c?.toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    search
                      ?.toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  ) ||
                ticket.Numero_del_ticket_principal__c?.toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    search
                      ?.toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  )
                  ||
                ticket.Name?.toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    search
                      ?.toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  )
              : ticket.SuppliedName
          )
          .map((pago, index) => {
            return (
              <ListItems
                key={index}
                item={pago}
                eleg={props.state}
                onChange={props.setState}
              />
            );
          })}
    </List>
  );
};

export default Lista;
