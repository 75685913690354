import { ErrorMessage } from "@hookform/error-message";
import { Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Fab from "@mui/material/Fab";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import IosShareIcon from "@mui/icons-material/IosShare";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import EditIcon from "@mui/icons-material/Edit";
import "react-data-table-component-extensions/dist/index.css";
import { useForm } from "react-hook-form";
import Loader from "react-js-loader";
import { NotificationManager } from "react-notifications";
import swal from "sweetalert2";
import { getProductos } from "../../services/producto";
import {
  addProd,
  addUbicacion,
  addUbicatedProd,
  changeName,
  deleteUbicatedProd,
  getUbicaciones,
  saveProd,
} from "../../services/stock";
import ModalExportar from "../modal/modal_exportar";
import ModalCameraStock from "../modal/modalCameraStock";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const paginationComponentOptions = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,
  selectAllRowsItemText: "Todos",
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto auto",
  },
  paper: {
    backgroundColor: "white",
    border: "2px solid #2a8daa",
    padding: '10px',
    borderRadius: "10px",
    "&:focus": {
      outline: "none",
    },
    line: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
      color: "grey",
    },
  },
}));

//Edición del componente que se muestra cuando se despliega una fila
const ExpandedComponent = ({ data }) => {
  return (
    <div
      style={{
        padding: "2px 0px",
        margin: "0px",
        fontSize: "13px",
        backgroundColor: "#D5E7ED",
        borderBottomLeftRadius: "15px",
        borderBottomRightRadius: "15px",
      }}
    >
      <Grid container>
        <Grid item sm={6}>
          <ul
            className="hola"
            style={{
              height: "130px",
              textAlign: "left",
              listStyleType: "square",
            }}
          >
            <li style={{ border: "none", padding: "0px 0px 0px 62px" }}>
              Nombre: <b>{data.nombre}</b>
            </li>
            <li style={{ border: "none", padding: "0px 0px 0px 62px" }}>
              Ubicación: <b>{data.ubicacion}</b>
            </li>
            <li style={{ border: "none", padding: "0px 0px 0px 62px" }}>
              Referencia ColorBaby: <b>{data.idProducto}</b>
            </li>
            <li style={{ border: "none", padding: "0px 0px 0px 62px" }}>
              Referencia Intex: <b>{data.referencia}</b>
            </li>
            <li style={{ border: "none", padding: "0px 0px 0px 62px" }}>
              Stock: <b>{data.stock}</b>
            </li>
          </ul>
        </Grid>
        <Grid item sm={6}>
          {data.image ? (
            <img
              style={{ maxHeight: "210px" }}
              src={data.image + "?v=" + new Date().toISOString()}
              alt="No se ha encontrado la imagen"
            />
          ) : (
            <img
              style={{ maxHeight: "210px" }}
              src={
                "https://www.distria.com/images/productos/intexservice/" +
                data.idProducto +
                ".jpg" +
                "?v=" +
                new Date().toISOString()
              }
              alt="No se ha encontrado la imagen"
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const Stock = (props) => {
  const [editable, setEditable] = useState(null);
  const [productos, setProductos] = useState(null);
  const [ubicaciones, setUbicaciones] = useState(null);
  const [ubicacion, setUbicacion] = useState(null);
  const [stock, setStock] = useState(null);
  const [lastUbi, setLastUbi] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const classes = useStyles();
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [ubiForm, setUbiForm] = useState(null);
  const [prodForm, setProdForm] = useState(null);
  const [search, setSearch] = useState();

  const [nomProd, setNomProd] = useState(null);
  const [refProd, setRefProd] = useState(null);
  const [idProd, setIdProd] = useState(null);

  const [nuevaUbicacion, setNuevaUbicacion] = useState(null);

  const [value, setValue] = React.useState(0);

  const [selectedProdEdit, setSelectedProdEdit] = useState(null);
  const [newName, setNewName] = useState(null);
  const [newReferencia, setNewReferencia] = useState(null);
  const [openExportar, setOpenExportar] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSend = async (data) => {
    data = { ...data, ubiForm, prodForm };
    const res = await addUbicatedProd(data);

    if (res?.status === 200) {
      NotificationManager.success("Creado con éxito");
    } else {
      NotificationManager.error("No se ha podido crear correctamente");
    }

    reset({ ubicacion: null, producto: null, stock: null });
    handleClose();
    setUbiForm(null);
    setProdForm(null);
  };

  const handleClickEdit = async () => {
    const res = await changeName(selectedProdEdit.id, newName, newReferencia);
    if (res.status === 200) {
      NotificationManager.success("Nombre cambiado con éxito");
      setProductos(
        productos.map((p) =>
          p.id === selectedProdEdit.id
            ? { ...p, label: newName, ref: newReferencia }
            : p
        )
      );
      props.setStock(
        props.stock.map((p) =>
          p.idProducto === selectedProdEdit.id
            ? { ...p, nombre: newName, referencia: newReferencia }
            : p
        )
      );
      setSelectedProdEdit(null);
      setNewName(null);
      setNewReferencia(null);
      setShowModalEdit(false);
    } else {
      NotificationManager.error("Se ha producido un error");
    }
  };

  // envio de formulario de crear producto
  const sendCreateForm = async () => {
    const data = { nombre: nomProd, referencia: refProd, id: idProd };
    const res = await addProd(data);
    if (res?.status === 200) {
      NotificationManager.success("Creado con éxito");
      setIdProd(null);
      setRefProd(null);
      setNomProd(null);
    } else {
      NotificationManager.error("¡ERROR! Revisa los campos.");
    }

    handleClose();
  };

  // envio de formulario de crear ubicacion
  const sendCreateUbicacionForm = async () => {
    const data = { nuevaUbicacion };
    const res = await addUbicacion(data);
    if (res?.status === 200) {
      NotificationManager.success("Creada con éxito");
      setNuevaUbicacion(null);
    } else {
      NotificationManager.error("¡ERROR! Revisa los campos.");
    }

    handleClose();
  };

  //Guardar en variables de clase la fila que va a ser editada para que se activen los inputs
  const changeEditable = (row) => {
    const ub = props?.stock?.find(
      (stockP) =>
        stockP.idProducto === row.idProducto &&
        stockP.ubicacion === row.ubicacion
    );
    setEditable(row.idProducto);
    setStock(row.stock);
    setUbicacion(ub);
    setLastUbi(ub.idUbicacion);
  };

  //Cambiar la ubicación de un producto
  const changeUbicacion = (ubicacion) => {
    ubicacion = ubicacion
      ? ubicaciones.find((ubi) => ubicacion === ubi.label)
      : null;
    setUbicacion({
      ubicacion: ubicacion?.label ?? null,
      idUbicacion: ubicacion?.id ?? null,
    });
  };

  //Guardar en la base de datos los cambios realizados en determinada fila, invoca al service
  const save = async (prod) => {
    if (
      ubicacion?.ubicacion !== null &&
      ubicacion?.ubicacion !== undefined &&
      ubicacion?.idUbicacion !== null &&
      ubicacion?.idUbicacion !== undefined &&
      stock !== null &&
      stock !== undefined
    ) {
      prod = {
        ...prod,
        idUbicacion: ubicacion.idUbicacion,
        ubicacion: ubicacion.ubicacion,
        stock: stock,
        lastUbi,
      };
      const res = await saveProd(prod);
      if (res?.status === 200) {
        NotificationManager.success("Actualizado con éxito");
        const newStock = props?.stock.map((stk) => {
          if (
            stk.idProducto === prod.idProducto &&
            stk.idUbicacion === lastUbi
          ) {
            return {
              ...stk,
              idUbicacion: prod.idUbicacion,
              ubicacion: prod.ubicacion,
              stock: prod.stock,
            };
          }
          return stk;
        });
        props?.setStock(newStock);
        setEditable(null);
        setLastUbi(null);
        setUbicacion(null);
        setStock(null);
      } else {
        NotificationManager.error("Error");
      }
    } else {
      NotificationManager.warning("Datos inválidos");
    }
  };

  // Eliminar la fila correspondiente
  const deleteUbProd = async (prod) => {
    if (prod) {
      const res = await deleteUbicatedProd(prod);
      if (res?.status === 200) {
        NotificationManager.success("Eliminado con éxito");
        const newStock = props?.stock.filter(
          (stk) =>
            stk.idProducto !== prod.idProducto ||
            stk.idUbicacion !== prod.idUbicacion
        );
        props?.setStock(newStock);
      } else {
        NotificationManager.error("Error");
      }
    }
  };

  // Muestra el modal de eliminar cuando pulsas el botón de eliminar
  const showDeleteModal = (row) => {
    // Swal es una libreria importada para hacer el modal
    swal
      .fire({
        title: "¿Estás seguro?",
        text:
          "¿Desea eliminar permanentemente el producto " +
          row.referencia +
          " ubicado en " +
          row.ubicacion +
          " ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#d33",
        borderButtonColor: "#d33",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteUbProd(row);
        }
      });
  };

  const handleUpdateImage = (image) => {
    props?.setStock(
      props.stock.map((st) =>
        st.idProducto === editable ? { ...st, image } : st
      )
    );
    setOpenCamera(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleCloseEdit = () => {
    setShowModalEdit(false);
  };

  useEffect(() => {
    const getUbi = async () => {
      const ubis = await getUbicaciones();
      setUbicaciones(
        ubis.reduce((ubicacionesMap, ubicacion) => {
          ubicacionesMap = [
            ...ubicacionesMap,
            { label: ubicacion.ubicacion, id: ubicacion.idUbicacion },
          ];
          return ubicacionesMap;
        }, [])
      );
    };
    if (!ubicaciones) getUbi();

    const getProds = async () => {
      const res = await getProductos();
      const prods = res.productos;
      setProductos(
        prods.reduce((prodsMap, prod) => {
          prodsMap = [
            ...prodsMap,
            {
              label:
                prod.referencia + " - " + prod.idProducto + " - " + prod.nombre,
              id: prod.idProducto,
              ref: prod.referencia,
            },
          ];
          return prodsMap;
        }, [])
      );
    };
    if (!productos) getProds();
  });

  const flatProps = {
    options: ubicaciones?.map((ubi) => ubi.label),
  };

  const columns = [
    {
      name: "Referencia CB",
      selector: (row) => row.idProducto,
      cellExport: (row) => row.idProducto,
      sortable: true,
      width: "15%",
    },
    {
      name: "Referencia Intex",
      selector: (row) => row.referencia,
      cellExport: (row) => row.referencia,
      sortable: true,
      width: "15%",
    },

    {
      name: "Nombre",
      selector: (row) => row.nombre,
      cellExport: (row) => row.nombre,
      sortable: true,
      width: "35%",
    },
    {
      name: "Ubicación",
      selector: (row) => row.ubicacion,
      cellExport: (row) => row.ubicacion,
      cell: (row) =>
        editable === row.idProducto ? (
          <Autocomplete
            {...flatProps}
            id="auto"
            defaultValue={flatProps.options.find(
              (opt) => opt === row.ubicacion
            )}
            size="small"
            onChange={(e, value) => changeUbicacion(value)}
            style={{ width: 130 }}
            renderInput={(params) => <TextField {...params} />}
          />
        ) : (
          row.ubicacion
        ),
      sortable: true,
      width: "10%",
    },
    {
      name: "Stock",
      cell: (row) =>
        editable === row.idProducto ? (
          <TextField
            size="small"
            id="text"
            defaultValue={row.stock}
            onChange={(e) => setStock(e.target.value)}
            fullWidth={false}
          />
        ) : (
          row.stock
        ),
      selector: (row) => row.stock,
      cellExport: (row) => row.stock,
      sortable: true,
      width: "10%",
    },
    {
      cell: (row) =>
        editable === row.idProducto ? (
          <Button onClick={() => setOpenCamera(true)}>
            <AddAPhotoIcon size="small" row={row} />
          </Button>
        ) : (
          <Button onClick={() => changeEditable(row)}>
            <ModeEditIcon size="small" row={row} />
          </Button>
        ),
      allowOverflow: true,
      button: true,
      width: "5%",
      selector: (row) => row.edit,
      cellExport: (row) => row.edit,
    },
    {
      cell: (row) =>
        editable === row.idProducto ? (
          <Button onClick={() => save(row)}>
            <SaveIcon size="small" row={row} />
          </Button>
        ) : (
          <Button onClick={() => showDeleteModal(row)}>
            <DeleteIcon style={{ color: "red" }} size="small" row={row} />
          </Button>
        ),
      allowOverflow: true,
      button: true,
      width: "5%",
      selector: (row) => row.delete,
      cellExport: (row) => row.delete,
    },
  ];

  const data =
    props?.stock &&
    props?.stock.filter((prod) =>
      search
        ? prod.referencia
            ?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              search
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            ) ||
          prod.nombre
            ?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              search
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )
        : prod.referencia
    );

  const tableData = {
    columns,
    data,
    filter: false,
    exportHeaders: true,
    fileName: "stockTaller",
  };

  return (
    <div className="tablePagination">
      <div>
        <Fab
          size="medium"
          variant="extended"
          onClick={() => setShowModal(true)}
          style={{
            backgroundColor: "#117278",
            color: "white",
            float: "right",
            marginTop: "10px",
          }}
        >
          <AddIcon sx={{ mr: 1 }} />
          Añadir
        </Fab>
        <Fab
          size="medium"
          variant="extended"
          onClick={() => setShowModalEdit(true)}
          style={{
            backgroundColor: "#77b300",
            color: "white",
            float: "right",
            marginTop: "10px",
            marginRight: "10px",
          }}
        >
          <EditIcon sx={{ mr: 1 }} />
          Editar Producto
        </Fab>
        <Fab
          size="medium"
          variant="extended"
          onClick={() => setOpenExportar(true)}
          style={{
            backgroundColor: "#009933",
            color: "white",
            float: "right",
            marginTop: "10px",
            marginRight: "10px",
          }}
        >
          <IosShareIcon sx={{ mr: 1 }} />
          Exportar
        </Fab>
        <TextField
          placeholder="Nombre o ref. Intex..."
          id="standard-bare"
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <FilterAltIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            float: "left",
            margin: "10px 0",
            backgroundColor: "white",
          }}
        />
      </div>
      {props?.stock ? (
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            highlightOnHover
            pointerOnHover
            data={data}
            defaultSortFieldId={1}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
          />
        </DataTableExtensions>
      ) : (
        <Loader
          type="bubble-loop"
          bgColor={"#318daa"}
          title={"Cargando..."}
          color={"#318daa"}
          size={100}
        />
      )}
      {showModal ? (
        <Modal
          open={showModal}
          onClose={handleClose}
          className={classes.modal}
          aria-labelledby="title"
          aria-describedby="description"
        >
          <div
            className={classes.paper}
            style={{ height: "410px", width: "600px" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Producto" {...a11yProps(0)} />
              <Tab label="Ubicación" {...a11yProps(1)} />
              <Tab label="Producto Ubicado" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <div className={classes.header}>
                <h3 id="title">Añadir producto</h3>
              </div>
              <hr color="#2a8daa" />
              <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid item xs={12}>
                  <TextField
                    style={{ width: "100%", marginTop: "10px" }}
                    variant="outlined"
                    className="textField"
                    label="Referencia Intex*"
                    type="text"
                    onChange={(e) => setRefProd(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    variant="outlined"
                    className="textField"
                    label="Referencia Colorbaby*"
                    type="text"
                    onChange={(e) => setIdProd(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    variant="outlined"
                    className="textField"
                    label="Nombre*"
                    type="text"
                    onChange={(e) => setNomProd(e.target.value)}
                  />
                </Grid>
              </Grid>
              <button
                type="submit"
                className="blue_button"
                style={{ margin: "20px 10px 10px 10px" }}
                onClick={() => sendCreateForm()}
              >
                + Añadir producto
              </button>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className={classes.header}>
                <h3 id="title">Añadir ubicación</h3>
              </div>
              <hr color="#2a8daa" />
              <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid item xs={12}>
                  <TextField
                    style={{ width: "100%", marginTop: "10px" }}
                    variant="outlined"
                    className="textField"
                    label="Nombre*"
                    type="text"
                    onChange={(e) => setNuevaUbicacion(e.target.value)}
                  />
                </Grid>
              </Grid>
              <button
                type="submit"
                className="blue_button"
                style={{ margin: "20px 10px 10px 10px" }}
                onClick={() => sendCreateUbicacionForm()}
              >
                + Añadir ubicación
              </button>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className={classes.header}>
                <h3 id="title">Añadir producto ubicado</h3>
              </div>
              <hr color="#2a8daa" />
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <form
                  onSubmit={handleSubmit(handleSend)}
                  noValidate
                  autoComplete="off"
                  style={{
                    display: "flex",
                    alingItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Grid container spacing={{ xs: 1, md: 2 }}>
                    <Grid item xs={12}>
                      <Autocomplete
                        className="textField"
                        id="ubicacion"
                        options={ubicaciones ?? []}
                        value={ubiForm}
                        getOptionLabel={(option) => option?.label}
                        name="ubicacion"
                        onChange={(event) => {
                          setUbiForm(
                            ubicaciones.find(
                              (ubi) => ubi.label === event.target.textContent
                            )
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Ubicación*"
                            {...register("ubicacion", {
                              required: "La ubicación es necesaria.",
                            })}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="ubicacion"
                        render={({ message }) => (
                          <p className="redColor">{message}</p>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        className="textField"
                        id="producto"
                        options={productos ?? []}
                        value={prodForm}
                        getOptionLabel={(option) => option?.label}
                        name="producto"
                        onChange={(event) => {
                          setProdForm(
                            productos.find(
                              (ubi) => ubi.label === event.target.textContent
                            )
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Producto*"
                            {...register("producto", {
                              required: "El producto es necesario.",
                            })}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="producto"
                        render={({ message }) => (
                          <p className="redColor">{message}</p>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        style={{ width: "100%" }}
                        variant="outlined"
                        className="textField"
                        label="Stock*"
                        type="number"
                        {...register("stock", {
                          required: {
                            value: true,
                            message: "El stock es necesario.",
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="stock"
                        render={({ message }) => (
                          <p className="redColor">{message}</p>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <button
                    type="submit"
                    className="blue_button"
                    style={{ margin: "20px 10px 10px 10px" }}
                  >
                    + Añadir producto ubicado
                  </button>
                </form>
              </div>
            </TabPanel>
          </div>
        </Modal>
      ) : null}
      <Modal
        open={showModalEdit}
        onClose={handleCloseEdit}
        className={classes.modal}
        aria-labelledby="title"
        aria-describedby="description"
      >
        <div
          className={classes.paper}
          style={{ height: "410px", width: "300px" }}
        >
          <div className={classes.header}>
            <h3 id="title">Editar producto</h3>
          </div>
          <hr color="#2a8daa" />
          <Autocomplete
            sx={{ marginTop: "15px" }}
            className="textField"
            id="producto"
            options={productos ?? []}
            value={selectedProdEdit}
            getOptionLabel={(option) => option?.label}
            name="producto"
            onChange={(event, value) => {
              setSelectedProdEdit(
                productos.find((ubi) => ubi.label === value.label)
              );
              setNewName(value.label);
              setNewReferencia(value.ref);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Producto*" />
            )}
          />
          {selectedProdEdit && (
            <>
              <TextField
                style={{ width: "100%", marginTop: "10px" }}
                variant="outlined"
                className="textField"
                label="Nombre"
                type="text"
                rows={6}
                multiline
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />
              <TextField
                style={{ width: "100%", marginTop: "10px" }}
                variant="outlined"
                className="textField"
                label="Referencia Intex"
                type="text"
                value={newReferencia}
                onChange={(e) => setNewReferencia(e.target.value)}
              />
              <button
                type="button"
                className="blue_button"
                style={{ margin: "20px 10px 10px 10px" }}
                onClick={handleClickEdit}
              >
                Editar
              </button>
            </>
          )}
        </div>
      </Modal>
      <ModalExportar close={() => setOpenExportar(false)} open={openExportar} />
      <ModalCameraStock
        close={() => setOpenCamera(false)}
        open={openCamera}
        idProduct={editable}
        updateStock={handleUpdateImage}
      />
    </div>
  );
};

export default Stock;
