import axios from "axios";

import "../config";

export const obtenerTickets = async () => {
  const config = {
    params: { access_token: global.token },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/tickets", config)
    .then((response) => {
      return response.data.records;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const obtenerTicketsInternos = async () => {
  const config = {
    params: { access_token: global.token },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/tickets/internos", config)
    .then((response) => {
      //return response.data.records;
      return response.data.records;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const obtenerToken = async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/tickets/login", config)
    .then((response) => {
      return response.data.access_token;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const obtenerTicket = async (id_ticket) => {
  const config = {
    params: { access_token: global.token },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/tickets/id/" + id_ticket, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const obtenerLineas = async (id_pedido) => {
  const config = {
    params: { access_token: global.token },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/tickets/lineas/" + id_pedido, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const obtenerTicketInterno = async (id_ticket) => {
  const config = {
    params: { access_token: global.token },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/tickets/interno-id/" + id_ticket, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const obtenerCliente = async (id) => {
  const config = {
    params: { access_token: global.token },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/tickets/cliente/" + id, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const marcarPagado = async (id) => {
  const config = {
    params: { access_token: global.token },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/tickets/estado/" + id, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const changeEstadoTicket = async (data) => {
  const config = {
    params: { access_token: global.token },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .put(global.ruta + "/tickets/cambiar-estado", { data }, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const changeEstadoTicketInterno = async (data) => {
  const config = {
    params: { access_token: global.token },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .put(global.ruta + "/tickets/cambiar-estado-interno", { data }, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const verificarTicketInterno = async (data) => {
  const config = {
    params: { access_token: global.token },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .put(global.ruta + "/tickets/verificar-interno", { data }, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addImagen = async (nombre, imagen, idTicket) => {
  const config = {
    params: { access_token: global.token, nombre: nombre, ticket: idTicket },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: imagen,
  };

  return await axios
    .post(global.ruta + "/tickets/add-imagen", { imagen: imagen }, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateLineas = async (data) => {
  const config = {
    params: { access_token: global.token },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .put(global.ruta + "/tickets/linea", { data }, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getProducts = async (id) => {
  const config = {
    params: { access_token: global.token },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/tickets/producto/" + id, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};