import logo2 from "../../images/logo_colorbaby.png";
import logo from "../../images/logo_intex.jpg";
import "./header.scss";

const Header = () => {
  return (
    <header className="App-header">
      <div className="menu-header">
        {localStorage.getItem("idSeccion") === "1" ? (
          <img src={logo} alt="Logo" id="logo" />
        ) : (
          <img src={logo2} alt="Logo" id="logo" />
        )}
      </div>
    </header>
  );
};

export default Header;
