import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../config";
import logoKO from "../../images/ko.png";
import logoOK from "../../images/ok.png";
import { getPresupuesto } from "../../services/presupuesto";
import { marcarPagado, obtenerToken } from "../../services/tickets";

const Confirmacion = (props) => {
  const [pagado, setPagado] = useState(0);
  const params = useParams();

  useEffect(() => {
    const getPres = async () => {
      const dev = await getPresupuesto(params.id);

      if (global.fecha_token === "") {
        global.token = await obtenerToken();
        global.fecha_token = new Date().toLocaleDateString();
      }

      if (params.res === "Bf5PEgOwl8mnvxFDTq6UBg==") {
        setPagado(1);
        const pagado = await marcarPagado(dev.presupuesto.idTicket);
      } else {
        setPagado(2);
      }
    };

    getPres();
  });

  return pagado == 1 ? (
    <div className="detail-content">
      <img src={logoOK} style={{ width: "150px" }} alt="ok" />
      <h2 style={{ margin: "20px" }}>¡El pago se ha relizado con éxito!</h2>
      <p>
        Gracias por hacer uso del servicio de repación de Intex Service Iberia.
      </p>
      <p>
        Vamos a proceder a repara la pieza y, una vez reparada, te la
        enviaremos.
      </p>
      <p>¡Nos vemos pronto!</p>
    </div>
  ) : pagado == 2 ? (
    <div className="detail-content">
      <img src={logoKO} style={{ width: "150px" }} alt="error" />
      <h2 style={{ margin: "20px" }}>¡Ha habido un problema con el pago!</h2>
      <p>
        Lamentamos el error. Por favor, inténtalo de nuevo pasados unos minutos.
      </p>
      <p>
        Si el error persiste ponte en contacto con nosotros a través del hilo
        del ticket abierto.
      </p>
      <p>Disculpa las molestias</p>
    </div>
  ) : (
    <div></div>
  );
};

export default Confirmacion;
