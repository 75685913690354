import axios from "axios";
import "../config";

export const createRepair = async (ticket, tipo, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const datos = {
    nombre: ticket.SuppliedName,
    tipo: tipo,
    ticket: ticket.CaseNumber,
    data: data,
  };

  return await axios
    .post(global.ruta + "/reparaciones/registrar", datos, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getReparacion = async (ticket) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/reparaciones?ticket=" + ticket, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setProductos = async (ticket, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const datos = {
    ticket: ticket,
    data: data,
  };

  delete datos.data.idTicket;

  return await axios
    .post(global.ruta + "/reparaciones/productos", datos, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setErrores = async (ticket, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const datos = {
    ticket: ticket,
    data: data,
  };

  return await axios
    .post(global.ruta + "/reparaciones/errores", datos, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setPruebas = async (ticket, idError, valor) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const datos = {
    idTicket: ticket,
    idError: idError,
    valor: valor,
  };

  return await axios
    .post(global.ruta + "/reparaciones/pruebas", datos, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setPiezas = async (ticket, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const datos = {
    ticket: ticket,
    data: data,
  };

  return await axios
    .post(global.ruta + "/reparaciones/piezas", datos, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getPruebas = async (ticket, tipoProducto) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(
      global.ruta +
        "/reparaciones/pruebas?tipoProducto=" +
        tipoProducto +
        "&idTicket=" +
        ticket,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setEstado = async (ticket, estado) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const datos = {
    ticket: ticket,
    estado,
  };

  return await axios
    .put(global.ruta + "/reparaciones/estado", datos, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getReparacionesByFecha = async (fechas) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(
      global.ruta +
        "/reparaciones/fecha?fechaInicio=" +
        fechas.fechaInicio +
        "&fechaFin=" +
        fechas.fechaFin,
      config
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getProductTypes = async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/reparaciones/tipos-producto", config)
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getTransportTypes = async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/reparaciones/transporte", config)
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getReceivedProducts = async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/reparaciones/productos-recibidos", config)
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getDefectTypes = async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/reparaciones/defectos", config)
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getWorkTypes = async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/reparaciones/tipos-trabajo", config)
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createDoneWorks = async (ticket, data, idSalesforce) => {
  const config = {
    params: { access_token: global.token },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const datos = {
    ticket,
    data,
    idSalesforce,
  };

  return await axios
    .post(global.ruta + "/reparaciones//trabajos-realizados", datos, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getDetailErrors = async (ticket, tipoProducto) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/reparaciones/detalle-errores", config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
