import { Modal } from "@mui/material";
import EtiquetaPage from "../../pages/etiqueta/etiquetaPage";
import Test from "../qrcode/reader";
import "./modal.scss";

const ModalQR = (props) => {
  return (
    <Modal
      aria-labelledby={props.reader ? "title" :"" }
      aria-describedby={props.reader ? "description" : "" }
      open={props.state}
      className={props.reader ? "modalqr" : "modal"}
      onClose={props.close}
    >
      {props.reader ? (
        <Test
          setState={props.setTicket}
          cerrar={props.close}
          tickets={props.tickets}
        />
      ) : (
        <EtiquetaPage
          ticket={props.ticket}
          cliente={props.cliente}
          idSalesforce={props.idSalesforce}
        />
      )}
    </Modal>
  );
};

export default ModalQR;
