import React, { useState } from "react";
import { Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { addDays, subDays } from "date-fns";
import es from "date-fns/locale/es";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { getReparacionesByFecha } from "../../services/reparacion";
import { read, writeFileXLSX } from "xlsx";
import * as XLSX from "xlsx/xlsx.mjs";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30%",
    margin: "70px auto",
  },
  paper: {
    backgroundColor: 'white',
    border: "2px solid #2a8daa",
    padding: '2px',
    borderRadius: "10px",
    "&:focus": {
      outline: "none",
    },
  },
  line: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  table: {
    display: "flex",
    justifyContent: "center",
    minHeight: "100px",
    padding: "10px",
  },
}));

const ModalExportar = (props) => {
  const classes = useStyles();
  const [fechas, setFechas] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  const handleChange = (ranges) => {
    const { selection } = ranges;
    setFechas([selection]);
  };

  const getExport = () => {
    const fechaInicio =
      fechas[0].startDate.getFullYear() +
      "-" +
      (fechas[0].startDate.getMonth() + 1) +
      "-" +
      fechas[0].startDate.getDate();

    const fechaFin =
      fechas[0].endDate.getFullYear() +
      "-" +
      (fechas[0].endDate.getMonth() + 1) +
      "-" +
      fechas[0].endDate.getDate();

    getReparacionesByFecha({ fechaInicio, fechaFin }).then((reps) => {
      reps = reps.data.map((rep) => ({
        ...rep,
        transporte:
          rep.transporte === 1
            ? "TIPSA"
            : rep.transporte === 2
            ? "ENVIALIA"
            : "OTROS",
        estado: rep.estado?.data[0] ?? null,
        fechaRegistro: rep.fechaRegistro.split("T")[0],
      }));
      const worksheet = XLSX.utils.json_to_sheet(reps);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Reparaciones");
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "idTicket",
            "Tipo de producto",
            "Peso",
            "Medida",
            "Observaciones",
            "Tiempo (h)",
            "Garantía",
            "Estado",
            "Bultos",
            "Nº Expediente",
            "Transporte",
            "Nombre cliente",
            "Fecha de Registro",
          ],
        ],
        { origin: "A1" }
      );
      XLSX.writeFile(
        workbook,
        "Reparaciones" + fechaInicio + "hasta" + fechaFin + ".xlsx"
      );
    });
  };

  return (
    <Modal
      aria-labelledby="title"
      aria-describedby="description"
      open={props.open}
      className={classes.modal}
      onClose={props.close}
      style={{ width: "50%", height: "50%" }}
    >
      <div className={classes.paper}>
        <div className={classes.header}>
          <h3 id="title">Exportar a CSV</h3>
        </div>
        <hr color="#2a8daa" />
        <DateRangePicker
          onChange={handleChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={fechas}
          direction="horizontal"
          maxDate={new Date()}
          minDate={new Date("01/01/2022")}
          locale={es}
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <button
            type="button"
            className="blue_button"
            style={{ margin: "20px 10px 10px 10px", cursor: "pointer" }}
            onClick={getExport}
          >
            Exportar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalExportar;
