import axios from "axios";

import "../config";

export const getStock = async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return await axios
    .get(global.ruta + "/stock/productos", config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getUbicaciones = async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return await axios
    .get(global.ruta + "/stock/ubicaciones", config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

//Servicio de editar en la tabla PRODUCTOSUBICADOS
export const saveProd = async (linProd) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await axios
    .put(global.ruta + "/stock/edit", linProd, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};

//Servicio de eliminar de la tabla PRODUCTOSUBICADOS
export const deleteUbicatedProd = async (linProd) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: {
      prod: linProd,
    },
  };

  return await axios
    .delete(global.ruta + "/stock/delete", config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};

//Añadr nuevo producto ubicado
export const addUbicatedProd = async (prodUbicado) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  const datos = {
    producto: prodUbicado?.prodForm,
    ubicacion: prodUbicado?.ubiForm,
    stock: prodUbicado?.stock,
  };

  return await axios
    .post(global.ruta + "/stock/nuevo", datos, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

// Añadir producto
export const addProd = async (prod) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  return await axios
    .post(global.ruta + "/products", prod, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

// Añadir ubicacion
export const addUbicacion = async (ubicacion) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  return await axios
    .post(global.ruta + "/stock/ubicaciones", ubicacion, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

// Añadir ubicacion
export const changeName = async (idProducto, nuevoNombre, nuevaReferencia) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  return await axios
    .put(
      global.ruta + "/products/nombre",
      { idProducto, nuevoNombre, nuevaReferencia },
      config
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const addImagenProd = async (imagen, idProduct) => {
  const config = {
    params: { product: idProduct },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: imagen,
  };

  return await axios
    .post(global.ruta + "/products/add-image", { imagen: imagen }, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};
