import React from "react";
import {useParams} from 'react-router-dom'
import QrCode from 'react-qr-code'
//import './showQR.scss';

  const ShowQR = () => {

    const params  = useParams()

    return (
        <QrCode id="QRCode" value={params.id}/>
    );
  
  } 

  export default ShowQR;