import React, { useState } from "react";
import "./login.scss";
import { loginUser } from "../../services/login";
import { TextField, InputAdornment } from "@mui/material";
import { AccountCircle, VpnKeyOutlined } from "@mui/icons-material";

const Login = (props) => {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await loginUser({
      username,
      password,
    }).then((res) => {
      if (res) {
        props.setToken(res);
      } else {
        setError("Usuario o contraseña incorrectos.");
      }
    });
  };

  return (
    <div className="login">
      <h2>Accede a tu cuenta</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          id="usuario"
          placeholder="Usuario"
          name="usuario"
          variant="outlined"
          onChange={(e) => {
            setError();
            setUserName(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          style={{ padding: "15px" }}
        />
        <TextField
          id="pass"
          placeholder="Contraseña"
          name="pass"
          variant="outlined"
          type="password"
          onChange={(e) => {
            setError();
            setPassword(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <VpnKeyOutlined />
              </InputAdornment>
            ),
          }}
          style={{ padding: "15px" }}
        />
        <p className="errorText">{error}</p>
        <br />
        <button className="blue_button" type="submit">
          Acceder
        </button>
        <br />
      </form>
    </div>
  );
};

export default Login;
