import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import "../../config";
import { TextField, Autocomplete } from "@mui/material";
import { useForm } from "react-hook-form";
import { getProductos } from "../../services/producto";
import ClearIcon from "@mui/icons-material/Clear";
import { setPiezas, getReparacion, setEstado } from "../../services/reparacion";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vh",
    margin: "0 auto",
  },
  paper: {
    backgroundColor: 'white',
    border: "2px solid #2a8daa",
    padding: '2px',
    borderRadius: "10px",
    "&:focus": {
      outline: "none",
    },
    width: "100vh",
  },
  line: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    color: "grey",
  },
  table: {
    display: "flex",
    justifyContent: "center",
    minHeight: "100px",
    padding: "10px",
  },
}));

const Piezas = (props) => {
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm();

  const [filas, setFilas] = useState(
    props.reparacion ? props.reparacion.lineas : []
  );
  const [productos, setProductos] = useState([]);
  const [ubicaciones, setUbicaciones] = useState([]);
  const [resultado, setResultado] = useState("");
  const [prodSelected, setProdSelected] = useState();

  useEffect(() => {
    const asyncFunc = async () => {
      const result = await getProductos(props.ticket);
      if (result) {
        setProductos(result.productos);
        setUbicaciones(result.ubicaciones);
      }
    };
    asyncFunc();
  }, [props.ticket]);

  const handleChange = async (data, e) => {
    let news = [...filas];
    const prod = productos.find((prod) => prod.nombre === data.referencia);
    const ubic = ubicaciones.find((ubi) => ubi.ubicacion === data.ubicacion);

    news.push({
      idTicket: props.ticket,
      idProducto: prod.idProducto,
      referencia: prod.referencia,
      nombre: prod.nombre,
      cantidad: data.cantidad,
      ubicacion: ubic.ubicacion,
      idUbicacion: ubic.idUbicacion,
    });
    setFilas(news);
    e.target.reset();
  };

  const handleSelectProd = async (value) => {
    await setProdSelected(value.idProducto);
  };

  const definirEstado = async () => {
    await setEstado(props.ticket, 1);
    const repar = await getReparacion(props.ticket);
    props.actualizar(repar);
  };

  const quitarPerfectoEstado = async () => {
    await setEstado(props.ticket, 0);
    const repar = await getReparacion(props.ticket);
    props.actualizar(repar);
  };

  const handleSend = async () => {
    const pres = await setPiezas(props.ticket, filas);
    if (pres) setResultado("¡Guardado con éxito! Si has eliminado alguna línea que ya estaba registrada, recuerda actualizar el stock manualmente");
    else setResultado("Se ha producido un error!");
    const repar = await getReparacion(props.ticket);
    props.actualizar(repar);
  };

  function borrarFila(data) {
    const temp = filas.filter((fila) => fila.idProducto !== data);
    setFilas(temp);
  }

  const lista = filas.map((fila) => {
    return (
      <tr>
        <td>
          <button type="button" onClick={() => borrarFila(fila.idProducto)}>
            <ClearIcon />
          </button>
        </td>
        <td>{fila.referencia}</td>
        <td>{fila.nombre}</td>
        <td>{fila.cantidad}</td>
        <td>{fila.ubicacion}</td>
      </tr>
    );
  });

  return (
    <>
      <div className={classes.table}>
        <table width="100%">
          <tr style={{ color: "#2a8daa" }}>
            <td></td>
            <td>Referencia</td>
            <td>Nombre</td>
            <td>Cantidad</td>
            <td>Ubicación</td>
          </tr>
          {filas.length === 0 ? (
            <tr>
              <td colSpan="4" style={{ color: "#C4C4C4" }}>
                No hay ninguna linea de presupuesto
              </td>
            </tr>
          ) : (
            lista
          )}
        </table>
      </div>
      <hr color="#2a8daa" />
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleChange)}
      >
        <div key={props.id} className={classes.line}>
          <Autocomplete
            disablePortal
            id="referencia"
            name="referencia"
            className="autocomplete"
            options={productos}
            getOptionLabel={(option) => option.nombre}
            onChange={(event, value) => handleSelectProd(value)}
            sx={{ m: 1, minWidth: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Referencia"
                name="referencia"
                {...register("referencia", {
                  required: { value: true, message: "Ingrese un nombre" },
                })}
              />
            )}
          />

          <Autocomplete
            disablePortal
            id="ubicacion"
            name="ubicacion"
            className="autocomplete"
            options={ubicaciones
              .filter((ubicacion) => ubicacion.idProducto === prodSelected)
              .map((ubs) => ubs)}
            getOptionLabel={(option) => option.ubicacion}
            sx={{ m: 1, minWidth: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ubicación"
                name="ubicacion"
                {...register("ubicacion", {
                  required: { value: true, message: "Ingrese un nombre" },
                })}
              />
            )}
          />
          <TextField
            id="cantidad"
            placeholder="Cantidad"
            name="cantidad"
            variant="outlined"
            {...register("cantidad", {
              required: { value: true, message: "Ingrese un nombre" },
            })}
            sx={{ m: 1, minWidth: 100 }}
          />

          <button
            type="submit"
            className="blue_button"
            style={{ margin: "10px" }}
          >
            + Añadir Línea
          </button>
        </div>
      </form>
      <div className={classes.line}>
        <button
          type="button"
          className="blue_button"
          onClick={handleSend}
          style={{ margin: "10px" }}
        >
          GUARDAR PIEZAS
        </button>
      </div>
      {props?.reparacion?.datos?.estado?.data[0] === 1 ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              color: "green",
            }}
          >
            El producto está en perfecto estado
          </p>
          <button
            type="button"
            className="red_button"
            onClick={quitarPerfectoEstado}
            style={{ margin: "10px" }}
          >
            QUITAR PERFECTO ESTADO
          </button>
        </div>
      ) : props?.reparacion ? (
        <div className={classes.line}>
          <button
            type="button"
            className="blue_button"
            onClick={definirEstado}
            style={{ margin: "10px" }}
          >
            PERFECTO ESTADO
          </button>
        </div>
      ) : null}

      <div className={classes.line}>{resultado}</div>
    </>
  );
};

export default Piezas;
