import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import ViewListSharpIcon from "@mui/icons-material/ViewListSharp";
import {
  Box, Grid,
  IconButton,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Loader from "react-js-loader";
import {
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import DetalleTicket from "../../components/detalle/detalleTicket";
import Lista from "../../components/lista/lista";
import Login from "../../components/login/login";
import ModalPistola from "../../components/modal/modalPistola";
import ModalQR from "../../components/modal/modalQR";
import Stock from "../../components/stock/stock";
import "../../config";
import { getStock } from "../../services/stock";
import {
  changeEstadoTicket,
  changeEstadoTicketInterno,
  obtenerTickets,
  obtenerTicketsInternos,
  obtenerToken
} from "../../services/tickets";
import "./taller.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Taller = () => {
  const [elegida, setElegida] = useState(null);
  const [pagos, setPagos] = useState(null);
  const [stock, setStock] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModalPistola, setShowModalPistola] = useState(false);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    async function fetchData() {
      if (global.fecha_token === "") {
        global.token = await obtenerToken();
        global.fecha_token = new Date().toLocaleDateString();
      }
      if (localStorage.getItem("idSeccion") === "1") {
        const dev = await obtenerTickets();
        setPagos(
          dev.map((ticket) => {
            return {
              ...ticket,
              CB_Descripcion__c: null,
              CB_Tracking__c: null,
            };
          })
        );
      } else {
        const dev = await obtenerTicketsInternos();
        setPagos(
          dev.map((ticket) => {
            return {
              ...ticket,
              SuppliedName: ticket.Nombre_del_cliente__c,
              CaseNumber: ticket.Numero_del_ticket_principal__c,
              Id: ticket.Ticket_principal__c,
              IdTicketInterno: ticket.Id,
              PedCli__c: ticket.CB_PedCli__c,
            };
          })
        );
      }
      const stock = await getStock();
      setStock(stock);
    }

    if (token) fetchData();
  }, [token]);

  if (!token) {
    return <Login setToken={setToken} />;
  }

  const handleElegida = async (val, hasChangeEstado) => {
    if (hasChangeEstado) {
      if (localStorage.getItem("idSeccion") === "1") {
        await changeEstadoTicket(val.idTicket);
      } else {
        await changeEstadoTicketInterno({
          id: val.idInterno,
          estado: "Recibido - Pdte Verificar",
        });
      }
    }
    setElegida(val.idTicket);
  };

  const handleClosePistola = () => {
    setShowModalPistola(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleRead = () => {
    setShowModal(true);
  };

  const handleReadPistola = () => {
    setShowModalPistola(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const cambiarEstado = async (data) => {
    const res = await changeEstadoTicketInterno(data);
    if (res?.status === 200) {
      NotificationManager.success("Estado actualizado con éxito.");
      setLoading(true);
    } else {
      NotificationManager.error("¡ERROR! Inténtalo más tarde.");
    }
    setLoading(false);
  };

  return (
    <div className="mainContainer">
      <div className="containerTab">
        {localStorage.getItem("idSeccion") === "1" ? (
          <>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="ticket tab"
              variant="fullWidth"
              textColor="secondary"
              style={{ maxWidth: "1224px", margin: "0 10px -15px 10px" }}
            >
              <Tab
                icon={<StickyNote2Icon />}
                label="Taller"
                style={{
                  backgroundColor: value === 0 ? "#D5E7ED" : null,
                  color: value === 0 ? "white" : null,
                  borderTopLeftRadius: "5px",
                }}
                {...a11yProps(0)}
              />

              <Tab
                icon={<FormatListBulletedIcon />}
                label="Stock"
                {...a11yProps(1)}
                style={{
                  backgroundColor: value === 1 ? "#D5E7ED" : null,
                  color: value === 1 ? "white" : null,
                  borderTopRightRadius: "5px",
                }}
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    padding: "0 5px",
                    borderRight: "solid #32a1ce",
                    margin: "20px 0 0 0",
                  }}
                >
                  {pagos ? (
                    <Lista
                      state={elegida}
                      setState={setElegida}
                      data={pagos}
                      openModal={handleRead}
                      openModalPistola={handleReadPistola}
                    />
                  ) : (
                    <Loader
                      type="bubble-loop"
                      bgColor={"#318daa"}
                      title={"Cargando..."}
                      color={"#318daa"}
                      size={100}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={8}>
                  {" "}
                  <DetalleTicket state={elegida} loading={loading} />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Stock stock={stock} setStock={setStock}></Stock>
            </TabPanel>{" "}
          </>
        ) : (
          <>
            {" "}
            <Grid
              container
              spacing={{ xs: 1, md: 2 }}
              style={{ padding: "0 10px" }}
            >
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  borderRight: isMobile ? null : "solid #32a1ce",
                  margin: elegida && isMobile ? 0 : "20px 0 20px 0",
                  display: elegida && isMobile ? "flex" : null,
                  alignItems: "left",
                  height: isMobile ? null : "90vh",
                }}
              >
                {pagos ? (
                  elegida && isMobile ? (
                    <IconButton
                      sx={{ margin: 0 }}
                      onClick={() => setElegida(null)}
                    >
                      <ViewListSharpIcon />
                    </IconButton>
                  ) : (
                    <Lista
                      state={elegida}
                      setState={setElegida}
                      data={pagos}
                      openModal={handleRead}
                      openModalPistola={handleReadPistola}
                    />
                  )
                ) : (
                  <Loader
                    type="bubble-loop"
                    bgColor={"#318daa"}
                    title={"Cargando..."}
                    color={"#318daa"}
                    size={100}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={8}>
                {elegida && (
                  <DetalleTicket
                    state={elegida}
                    cambiarEstado={cambiarEstado}
                    loading={loading}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </div>
      <ModalPistola
        state={showModalPistola}
        close={handleClosePistola}
        setTicket={handleElegida}
      />
      <ModalQR
        state={showModal}
        reader={true}
        close={handleClose}
        setTicket={handleElegida}
        tickets={pagos}
      />
    </div>
  );
};

export default Taller;
